.navbar {
  background-color: #00a5d0;
  padding: 12px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

h3 {
  margin: 0px 0px;
  font-size: 24px;
}

a {
  color: white;
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  margin: 0px 0px 0px 12px;
}

p,
li {
  padding: 0px 0px 0px 8px;
}

p {
  font-weight: 600;
}

.Purchased {
  text-decoration: line-through;
}